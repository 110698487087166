<template>
  <template-card
    :name="name"
    preview-width="60px"
    preview-height="40px"
    icon="$paper_barcode"
    @dragend:item="addItemDrop()"
    @click:item="addItem()" />
</template>
<script>
import paperBuilderToolMixin from '@/mixins/paperBuilderToolMixin'
import { v4 as uuid } from 'uuid'
import TemplateCard from './TemplateCard.vue'

export default {
  components: { TemplateCard },
  mixins: [
    paperBuilderToolMixin
  ],
  methods: {
    addItem () {
      const { left, top } = this.paperBuilderDrop || { left: '20px', top: '20px' }
      this.addPaperBuilderCanvas({
        id: this.paperBuilderId,
        data: {
          id: uuid(),
          type: 'barcode',
          tag: 'img',
          data: 'order',
          fx: { },
          properties: {
            src: 'order.barcode',
            style: {
              'position': 'absolute',
              left,
              top,
              'width': '60px',
              'height': '40px',
              '-webkit-backface-visibility': 'hidden'
            }
          },
          children: []
        }
      })
    }
  }
}
</script>
<style scoped>

</style>
